.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.726); 
    z-index: 9; 
    top: 0;
    left: 0;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    font-weight: 100;
    overflow-y: auto;
    p {
        text-align: center;
        font-size: 1.2rem;
        background-color: white;
        width: 80%;
        border-radius: 0.5rem;
        margin-bottom: 5px;  
        padding: 25px;
    }
    .buttonGroup {
        display: flex;
        justify-content: center; 
        gap: 0;
        flex-direction: column;  
        align-items: center;  
        width: 40%;  
        max-width: 400px;  
        .confirmButton, .cancelButton {
            background-color: #2b5276;
            color: white;
            padding: 12px 20px;
            
            border: none;
            border-bottom: 5px solid #081D31;
            border-radius: 0.5rem;
            cursor: pointer;
            
            font-size: 1.5rem;
            line-height: 1;
            width: 100%;  
            margin: 5px 0;
            &:hover{
			background-color: #AFC5DA;
			color: #2B5276;
		    }
        }
    } 
}    
.modalClue {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 0.5rem;
    margin: 10px 10px; 
    .headerTitle {
        padding-bottom: 10px;
    }
    .text {
        padding-top: 0;
    }
}
.closeButton {
    background-color: hsl(209, 47%, 32%);
    color: white;
    padding: 12px 20px;
    border: none;
    border-bottom: 5px solid #081D31;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 100;
    line-height: 1;
    width: 45%;
    &:hover{
		background-color: #AFC5DA;
		color: #2B5276;
	}
}
