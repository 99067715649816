@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

.VelhoPirata {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: "Poppins", sans-serif;
    .bgHeader {
        width: 100%;
        max-width: 600px;
        display: flex;
        justify-content: center;
        img {
            width: 100%;
            height: auto;
            
        }
    }

    .content {
        max-width: 600px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 0.5rem;
        text-align: left;

        p {
            display: inline-block;
            font-size: 1.1rem;
            font-weight: bold;
            border-bottom: 1px solid #4A302B;
            padding-bottom: 4px;
            text-align: left;
            color: #4A302B;
            max-width: 80%;
            margin-left: 20px;
        }

        span {
            font-size: 0.8rem;
            line-height: 1.6;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        img {
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
        }
        .footerImage img{
                width: 100%;
                max-width: 400px;
                display: block;
            }
        }
    }



@media (min-width: 768px) {
    .VelhoPirata {
        padding: 1rem;
        .content {
            max-width: 800px;
        }
    }
}

@media (min-width: 1024px) {
    .VelhoPirata {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .bgHeader {
            max-width: 500px;
        }
        .content {
            text-align: left;
        }
        
    }
}
