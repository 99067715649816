.header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    
    .logo {
        display: flex;
        align-items: center;
        height: 100%;
        
    }
    .nav {
        display: flex;
        align-items: center;
        gap: 20px; 
    }
    .button {
        cursor: pointer;
        background: none;
        border: none;
        color: white;
        font-size: 10px;
    }
}
  
  
