
.home {
    width: calc(100vw - 2rem);
    min-height: calc(100vh - 2rem);
    padding: 1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    background-position: top;
	background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow-y: hidden;
    *{
        font-family: "Poppins", sans-serif;
    }

}
