.pocketPuzzle {
	max-width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	position: relative;
	z-index: 0;
	gap: 1rem;
	overflow: hidden;
	
	*{
		font-family: "Kufam", sans-serif;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: -1;
	}
	.header {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		.headerImage {
			max-width: 70%;
		}
	}
}
.errataButton {
	width: 60%;
	height: 3rem;
	background-color: #DF3939;
	border: none;
	border-bottom: 5px solid #781111;
	border-radius: 0.5rem;
	font-size: 1.2rem;
	cursor: pointer;
	color: white;
	&:hover {
		background-color: #F67F7F;
		color: #832B2B;
	}
}
.guideButton {
	width: 50%;
	min-height: 3rem;
	background-color: #2b764d;
	border: none;
	border-bottom: 5px solid #244533;
	border-radius: 0.5rem;
	font-size: 1.0rem;
	cursor: pointer;
	color: white;
	&:hover {
		background-color: #A0E8C0;
		color: #2B764D;
	}
}
.cluesComponent{
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	justify-content: center;
	width: 80%;
	.headerClues {
		color: white;
		font-size: 1.2rem;
	}
	.clueList {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1.5rem;
		width: calc(80% - 40px);
		justify-items: center;
	
		.clueButton {
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc(100% - 2rem);
			max-height: fit-content;
			background-color: #2b5276;
			border: none;
			border-bottom: 5px solid #081d31;
			border-radius: 0.5rem;
			font-size: 1.1rem;
			cursor: pointer;
			line-height: 1;
			color: white;
			padding: 1rem 1.5rem;
			height: fit-content;
	
			&:hover {
				background-color: #afc5da;
				color: #2b5276;
			}
	
			&.disabled {
				background-color: #c1c1c1;
				border-bottom: 5px solid #7c7c7c;
			}
		}
	}
}

@media (max-width: 768px) {
	.pocketPuzzle {
		gap: 0.5rem !important;
	}
	.clueList{
		width: 85% !important;
		gap: 0.5rem !important;
	}
	.guideButton {
		width: calc(50% - 2rem);
		font-size: 0.9rem;
		padding: 0.8rem 1rem;			
	}
}
