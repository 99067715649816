@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Kufam:ital,wght@0,400..900;1,400..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.modalErratas {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
    
    .modalContent {
        text-align: center;
        font-size: 1.0rem;
        background-color: white;
        width: 80%;
        max-width: 100%;
        border-radius: 0.5rem;
        margin-bottom: 15px;  
        padding: 25px;
        position: relative;
        overflow-y: auto;
        p {
            margin-bottom: 10px;
            text-align: justify;
        }
        .image1 {
            width: 300px; 
            height: auto;
            margin: 10px 0;
            
        }
        .redText {
            color: #FF0000;
        }
        .greenText {
            color: #2F9609;
        }
    }
}
.closeButton {
    background-color: #2b5276;
    color: white;
    padding: 12px 20px;
    border: none;
    border-bottom: 5px solid #081D31;
    border-radius: 0.5rem;
    cursor: pointer; 
    font-size: 1.2rem;
    line-height: 1;
    width: 80%;  
    &:hover{
	    background-color: #AFC5DA;
	    color: #2B5276;
		}
}
