.audioPlayerComponent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// width: 75%;
	gap: 0.5rem;
	.headerAudio {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: white;
		// padding-bottom: 2%;
	}
	.audioPlayer {
		width: 0 !important;
		height: 0 !important;
	}
	.controlButton {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #081d31;
		padding: 0.5rem 1rem;
		width: 100%;
		border: none;
		border-radius: 0.5rem;
		max-height: 100px;
	    max-width: 300px;
		cursor: pointer;
		.icon {
			max-height: 40px;
			width: 25%;
		}
	}
}

@media (max-width: 768px) {
	.controlButton{
		width: 50% !important;
		border-radius: 0.3rem !important;
	}
}
