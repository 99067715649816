@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

.Manual {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	font-family: 'Poppins', sans-serif;
	.wrapperVideos {
		display: flex;
		flex-direction: column;
        padding: 1rem;
		justify-content: center;
		align-items: center;
        gap: 1rem;
        p{
            font-size: 1.3rem;
            font-weight: 600;
        }
		.wrapperBtns {
			width: calc(100% - 2rem);
			display: flex;
			gap: 1rem;
			justify-content: center;
			align-items: center;
			// padding: 1rem;
			.btn {
                cursor: pointer;
				border: none;
				// background-color: ;
				padding: 1rem 2rem;
				border-radius: 2rem;
				font-family: 'Poppins', sans-serif;
				font-weight: 500;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
	}

	.bgHeader {
		width: 100%;
		max-width: 600px;
		display: flex;
		justify-content: center;

		img {
			width: 100%;
			height: auto;
		}
	}

	.content {
		max-width: 600px;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: 0.5rem;
		text-align: left;

		p {
			display: inline-block;
			font-size: 1.1rem;
			font-weight: bold;
			color: #4a302b;
			max-width: 80%;
			margin-left: 20px;
		}

		span {
			font-size: 0.8rem;
			line-height: 1.6;
			padding-left: 2rem;
			padding-right: 2rem;
		}

		img {
			width: 100%;
			max-width: 300px;
			align-self: center;
		}

		.containerPassword {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 20px;

			.imgPassword {
				width: 30%;
				margin: 0;
			}
		}

		.footerImage {
			width: 100%;
			max-width: 600px;
			display: flex;
			justify-content: center;

			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

@media (min-width: 768px) {
	.VelhoPirata {
		padding: 1rem;

		.content {
			max-width: 800px;
		}
	}
}

@media (min-width: 1024px) {
	.VelhoPirata {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;

		.bgHeader {
			max-width: 500px;
		}

		.content {
			text-align: left;
		}
	}
}
