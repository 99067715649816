@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.investigadores {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	justify-content: flex-start;
	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 100%;
		height: 30%;

		.bgImage {
			top: 0%;
			left: 0%;
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
			z-index: 1;
		}
		.wrapperLogos {
			width: calc(100% - 6rem);
			display: flex;
			align-items: center;
			justify-content: space-between;
			z-index: 2;
			padding: 0 3rem;
			.cm {
				width: 15%;
			}
			.game {
				width: 20%;
			}
		}
	}

	.body {
		display: flex;
		align-items: center;
		height: 70%;
		width: 100%;
		background-color: #212028;
		flex-direction: column;
		.wrapperBtn {
			display: flex;
			flex-direction: column;
			gap: 2.5rem;
            margin-top: 5rem;

			h3 {
				font-family: 'Poppins', sans-serif;
				color: white;
				font-size: 1.2rem;
			}
			button {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
				border: none;
				border-radius: 1rem;
				background-color: #c33939;
				color: white;
				padding: 0.5rem 2rem;
				transition: transform 0.2s ease;
				cursor: pointer;
			}
			button:hover {
				transform: scale(1.1);
			}
		}
		.updatesWraper {
			display: flex;
			flex-direction: column;
			align-self: flex-start;
			justify-self: flex-start;
			padding: 1rem;
			color: white;
			font-family: 'Poppins', sans-serif;
			margin-left: 10rem;
			.updates {
				padding: 2rem;
				li {
					padding: 0 1rem;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.header {
		.wrapperLogos {
			width: 100%;
			display: flex;
            flex-direction: column;
			align-items: center;
			justify-content: space-between;
			z-index: 2;
			padding: 0 3rem;
			.cm {
				width: 40% !important;
			}
			.game {
				width: 90% !important;
			}
		}
	}
    .body {
		gap: 2rem;
		.wrapperBtn {
			display: flex;
			flex-direction: column;
			gap: 2.5rem;
            margin-top: 5rem;
			padding: 0 !important;
			h3 {
				font-family: 'Poppins', sans-serif;
				color: white;
				font-size: 1.2rem;
                text-align: center;
			}
			button {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 1rem;
				border: none;
				border-radius: 1rem;
				background-color: #c33939;
				color: white;
				padding: 0.5rem 2rem;
				transition: transform 0.2s ease;
				cursor: pointer;
			}
			button:hover {
				transform: scale(1.1);
			}
		}
		.updatesWraper {
			display: flex;
			flex-direction: column;
			align-self: flex-start;
			justify-self: flex-start;
			padding: 1rem;
			color: white;
			font-family: 'Poppins', sans-serif;
			margin-left: 2rem !important;
			.updates {
				padding: 2rem;
				li {
					padding: 0 1rem;
				}
			}
		}
	}
}
